import { graphql } from 'gatsby';
import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/react';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from './post';
import PostContent from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

interface PageTemplateProps {
  pageContext: {
    slug: string;
  };
  data: {
    logo: {
      childImageSharp: any;
    };
    markdownRemark: {
      html: string;
      htmlAst: any;
      excerpt: string;
      timeToRead: string;
      frontmatter: {
        title: string;
        date: string;
        userDate: string;
        image: {
          childImageSharp: any;
        };
        tags: string[];
        author: {
          id: string;
          bio: string;
          avatar: {
            children: Array<any>;
          };
        };
      };
    };
    relatedPosts: {
      totalCount: number;
      edges: Array<{
        node: {
          timeToRead: number;
          frontmatter: {
            title: string;
          };
          fields: {
            slug: string;
          };
        };
      }>;
    };
  };
  pageContext: {
    prev: PageContext;
    next: PageContext;
  };
}

export interface PageContext {
  excerpt: string;
  timeToRead: number;
  fields: {
    slug: string;
  };
  frontmatter: {
    image: {
      childImageSharp: any;
    };
    title: string;
    date: string;
    tags: string[];
    author: {
      id: string;
      bio: string;
      avatar: {
        children: Array<any>;
      };
    };
  };
}

const AboutTemplate: React.FC<PageTemplateProps> = props => {
  const post = props.data.markdownRemark;
  return (
    <IndexLayout>
      <Helmet>
        <title>About</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <SiteNav />
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader>
              <PostFullTitle>About</PostFullTitle>
            </PostFullHeader>

            <PostContent htmlAst={post.htmlAst} />
          </article>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default AboutTemplate;

export const pageQuery = graphql`query ($slug: String, $primaryTag: String) {
  logo: file(relativePath: {eq: "img/20200321-Logo Bruhn-Natur.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FIXED)
    }
  }
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    htmlAst
    excerpt
    timeToRead
    frontmatter {
      title
      userDate: date(formatString: "D MMMM YYYY")
      date
      tags
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      author {
        id
        bio
        avatar {
          children {
            ... on ImageSharp {
              fixed(quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
  relatedPosts: allMarkdownRemark(
    filter: {frontmatter: {tags: {in: [$primaryTag]}}, isReleased: {eq: true}}
    limit: 3
  ) {
    totalCount
    edges {
      node {
        id
        timeToRead
        excerpt
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
